import {
  ContentstackModularBlocks,
  ContentstackPageHeader
} from '@stuller/stullercom/feat/contentstack-shared'
import { useFilterContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { useCategoryRecommendations } from '@stuller/stullercom/feat/recommendations'
import { MetaDescription, Title } from '@stuller/stullercom/feat/layout'
import { getInitialProps } from './getInitialProps'
import { type CategoryPageType } from './types'
import { isStringEmpty } from '@stuller/shared/util/core'

/**
 * The Page that will render the content entries with the Category Page content type
 */
const CategoryPage: CategoryPageType = ({ categoryData, categoryCmsData }) => {
  useCategoryRecommendations([{ placementId: 'category_page' }], categoryData.id)
  const page = categoryCmsData?.all_category_page?.items?.[0]
  const pageContents = page?.page_contents
  const pageHeaders = useFilterContentstackTargeting(
    page?.page_headerConnection?.edges?.map(header => ({
      item: header?.node,
      targeting: header?.node?.targetingConnection?.edges?.[0]?.node?.targeting
    }))
  )

  return (
    <>
      {!isStringEmpty(categoryData.pageTitle) && <Title>{categoryData.pageTitle}</Title>}
      {categoryData.pageMetaDescription != null && <MetaDescription>{categoryData.pageMetaDescription}</MetaDescription>}

      <ContentstackPageHeader data={pageHeaders[0]} />

      <ContentstackModularBlocks
        modularBlocks={pageContents}
        prefix='CategoryPagePageContents'
      />
    </>
  )
}

CategoryPage.getInitialProps = getInitialProps

export {
  CategoryPage
}
